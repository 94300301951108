<template>
    <div>
        <div class="login_form">
            <p style="color:red;font-size:16px;">{{errorMsg}}</p>
            <p style="color:#666;">欢迎添加客服微信,咨询使用</p>
            <p><img src="../../assets/img/kf.jpg" style="width:100%;" /></p>
            <p>QQ:3052894409</p>
            <p>电话:<a href="tel://15895914665">15895914665</a></p>
            <p v-if="errorMsg == '账号不存在'" style="text-align:center;"><van-button @click="clearAccount" type="info">清空重试</van-button></p>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                errorMsg: localStorage.errorMsg,
                user: JSON.parse(localStorage.userInfo),
                
                total: 0,
                page: 1,
                size: 20,
                list: [],
                loading: false,
                finished: false,
                error: false,
                refreshing: false,
                
            };
        },
        created() {
        },
        methods: {
            clearAccount() {
                localStorage.removeItem('userInfo');
                alert('清除成功，请关闭后重新进入系统');
            }

        },

        mounted() {
        }
    };
</script>

<style lang="less" scoped>
    
</style>
<style lang="less">
.login_form {
    padding: 30px;;
}
.login_form p {
    line-height: 40px;
}
    .van-nav-bar .van-icon , .van-nav-bar__text {
        color: #20a0ff;
    }
</style>
